export const formatCOPNumber =(numero, mostrarCentavos = false) => {
    // Asegurarse de que el número sea un valor numérico
    if (isNaN(numero)) {
      return "0";
    }
  
    // Redondear el número a 2 decimales si se muestran centavos
    if (mostrarCentavos) {
      numero = Number(numero).toFixed(2);
    }
  
    // Convertir el número a una cadena
    const numeroStr = numero.toString();
  
    // Usar una expresión regular para agregar comas como separadores de miles
    const regex = /(\d)(?=(\d{3})+(?!\d))/g;
    const numeroFormateado = numeroStr.replace(regex, "$1,");
  
    // Agregar el símbolo de peso colombiano y, si es necesario, los centavos
    // const simbolo = mostrarCentavos ? " COP" : " COP";
    const simbolo = '$ ';
    return simbolo+numeroFormateado;
  }

  export const FORMAT = {
    FormatCOP: (number) => {
        const formatterPeso = new Intl.NumberFormat('es-CO', {
            style: 'currency',
            currency: 'COP',
            minimumFractionDigits: 0
        })
        if (!number && number !== 0) {
            return formatterPeso.format(0)
        } else {
            return formatterPeso.format(number)
        }
    },
    FormatDocument(num) {
        try {
            var num = num.replace(/\./g, '');
            if (!isNaN(num)) {
                num = num.toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1.');
                num = num.split('').reverse().join('').replace(/^[\.]/, '');
                return num;
            }

            else {
                return "";
            }
        }
        catch (error) {
            return "";
        }
    },
    formatCOPNumberInput : (number, commas = false) => {
        try {
            if (!number) {
                return ''
            }
            const valorNumerico = number.toString().replace(/[^\d]/g, "");
            const valorFormateado = valorNumerico.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
            return `$${valorFormateado}`;
    
        }
        catch (error) {
            console.log('error formatCOPNumber ', error);
            return "";
        }
    }, GetDigits: (text) => {
        if (!text || text === '' || text === ' ') return 0
        return text.toString().replace(/\D/g, "");
    }, GetNumber: (s) => {
        try {

            if (!s) {
                return 0;
            }
            // Eliminar el signo de dólar y los separadores de miles y decimales
            s = s.replace(/\$|\./g, '').replace(',', '.');
            // Convertir el string a un número de punto flotante
            return parseFloat(s);
        } catch (error) {
            return 0
        }
    }, formatPhoneNumber: (text) => {
        // let t = text.replace(/\D/g,""); //quitamos los caracteres que no sean digitos

        // t = t.substring(0, 11);// el maximo de digitos para el numero de telefono es 12
        // t = t.replace(/^(\d{3})/g, "("+t.substring(0,3)+")" ); //agregamos los parentesis. 
        // t = t.replace(/\)(\d{3})/g, ") "+t.substring(5,8)+"-" ); //agregamos un espacio y el primer guion

        let t = text.replace(/^(\d{3})(\d{3})(\d{}).*/, '($1) $2-$3'); //quitamos los caracteres que no sean digitos
        //console.log('number,', t)
        t = t.substring(0, 16);// el maximo de digitos para el numero de telefono es 12
        //t = t.substring(0, 14);// el maximo de digitos para el numero de telefono es 12
        t = t.replace(/^(\d{3})/g, "(" + t.substring(0, 3) + ")"); //agregamos los parentesis. 
        t = t.replace(/\)(\d{3})/g, ") " + t.substring(5, 8) + "-"); //agregamos un espacio y el primer guion

        return t
    }

}

export const isANumber = (s) => {
    // Verificar si el string es un valor numérico válido
    if (isNaN(s)) {
        return false;
    }
    // Comprobar si el string contiene un punto decimal o no
    if (s?.indexOf('.') > -1) {
        return true;
    } else {
        return true;
    }
}

export const getOS = () => {
    const userAgent = navigator.userAgent;

    if (userAgent.includes('Macintosh')) {
        return 'Mac';
    } else if (userAgent.includes('iPhone') || userAgent.includes('iPad') || userAgent.includes('iPod')) {
        return 'iOS';
    } else if (userAgent.includes('Windows')) {
        return 'Windows';
    } else if (userAgent.includes('Android')) {
        return 'Android';
    }

    return 'Desconocido';
}

export function removeBase64Prefix(base64String) {
    // Obtenemos el prefijo
    const prefix = 'data:image/jpeg;base64,';

    // Eliminamos el prefijo del string
    return base64String?.replace(prefix, '');
}

export const getIconsBetting=(item) => {
    let icon=null
    switch (item.name) {
        case 'DEPOSITO BWIN':
            icon=require('../assets/logosBetting/bwinLogo.png')
            break;
    
        default:
            break;
    }
    return icon
  
}