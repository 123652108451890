import React, { useEffect, useState } from 'react'

import { TiArrowSortedDown } from "react-icons/ti"
import { HiSearch } from "react-icons/hi"

import Modal from '../Modal'
import ListItem from './ListItem'
const SelectModal = ({ items = [], selectedItem = {}, setSelectItem = () => { }, customLabelKey = '',showSearchBar=false }) => {
    const [showModal, setShowModal] = useState(false)
    const [search, setSearch] = useState('')
    const [itemsArray, setItemsArray] = useState(items)
    const filterArray = (array, busqueda) => {
        const busquedaLowerCase = busqueda.toLowerCase();

        const resultadoFiltrado = array.filter(item => {
            const labelLowerCase = item.label.toLowerCase();
            return labelLowerCase.includes(busquedaLowerCase);
        });

        return resultadoFiltrado;
    }
    useEffect(() => {

        setItemsArray(filterArray(items, search))

    }, [search, items])

    return (
        <>
            <button onClick={() => setShowModal(true)} className="w-full bg-white rounded-2xl outline-none px-4 py-3 flex flex-row items-center justify-between overflow-hidden">
                <p className='truncate'>{customLabelKey ? selectedItem?.[customLabelKey] : selectedItem?.label}</p>
                <TiArrowSortedDown />
            </button>
            <Modal showModal={showModal} setShowModal={setShowModal}>
                {
                    showSearchBar?

                    <div className='flex justify-center items-center border-y-2 border-y-labelInputColor px-4 w-full'>
                    <div className='flex justify-center items-center'>

                        <HiSearch size={25} color={'#CBCEE7'} />
                    </div>
                    <input value={search} placeholder='Buscar' onChange={(e) => setSearch(e.target.value)} className='h-14 w-full px-2  outline-none ' />
                </div>
                :
                null
                }
                <div className='w-full h-full '>

                    {
                        itemsArray && itemsArray.length > 0 && itemsArray.map((item, i) => {
                            return (
                                <ListItem key={i} title={item?.label} className={selectedItem?.value === item.value && item.value !== '' ? 'bg-primary text-white' : i === 0 &&item.value == ''? 'text-labelInputColor' : ''} onClick={() => {

                                    if (item.value !== '') {
                                        setSelectItem(item)
                                        setShowModal(false)

                                    }
                                }} />
                            )
                        })
                    }
                </div>
            </Modal>
        </>
    )
}

export default SelectModal