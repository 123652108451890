import React from 'react'
import BackgroundHome from '../components/BackgroundHome'
import { formatCOPNumber } from '../functions'
import { useLocation } from 'react-router-dom'
import { IoCloseSharp } from "react-icons/io5";

const labele=[{labelL:'Monto a Pagar',labelR:'10000',bold:true,capitalize:false},{labelL:'Monto a Pagar',labelR:'10000'},{labelL:'Monto a Pagar',labelR:'10000'},{labelL:'Monto a Pagar',labelR:'10000',bold:true}]
const ComfirmationPage = ({headerText='REF-0000',subtitle=`Tu recarga ha 3160403343 por $10.000 ha sido exitosa!`,dataInfo=labele}) => {
  let { state } = useLocation();

  return (
    <div className="bg-main h-screen flex-col items-center w-full flex  bg-main">
    <BackgroundHome/>
    <div className='w-full h-8 flex z-10'>
      <div className='w-full flex items-end justify-center'></div>
      <div className='w-full text-center flex items-end justify-center'>
        <p className=''>{state.headerText}</p>
      </div>
      <div className='w-full flex justify-end'>
{/*       <IoCloseSharp size={'30px'}/>
 */}
      </div>

    </div>
    <div className='container-data'>

    <h1 className='text-center'>{state.title}</h1>
    <div className='flex justify-center py-5'>

    <img alt='success-icon' className='w-20' src={require('../assets/icons/checkIconComfirmation.png')}></img>
    </div>
    <div className='px-4 text-center flex justify-center w-full items-center'>
    {
      state?.subtitle
      ?
      <h3 className='text-center my-2'>{state.subtitle}</h3>
      :
      null
    }
    {
      state?.pin
      ?
      <div className='w-2/4'>

      <div className='payment-input bg-white'>
        <p className='text-primary font-bold'>PIN:</p>
         <h1>{state?.pin}</h1>
      </div> 
      </div>
      :
      null
    }
    </div>
    <div className='my-4 w-full'>
    <div className='payment-input bg-white'>
      {state?.dataInfo.map((item,index)=>(

        <div key={index} className={`flex flex-row justify-between my-2 text-sm ${item?.bold?'':'text-gray-500'} ${item?.capitalize?'capitalize':''}`}>
                <p>{item.labelL}</p>
                <p>{item.labelR}</p>
              </div>
      ))}
             
            </div> 
    </div>


{/*     <button onClick={()=>{}} className={`btn`}>Compartir</button>
 */}
    </div>

</div>
  )
}

export default ComfirmationPage