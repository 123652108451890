import React, { useEffect, useState } from 'react'
import NavBarCobru from '../components/NavBarCobru'
import { Carousel } from '../components/Carousel'
import { ItemListProducts } from '../components/ItemListProducts'
import BackgroundHome from '../components/BackgroundHome'
import axios from 'axios'
import moment from 'moment'
import { formatCOPNumber } from '../functions'
import { useNavigate } from 'react-router-dom'

const ProductServiceCobru = ({refresh,optionsMethod=[],currentMethod=null}) => {
  const navigate = useNavigate();

    const [paymentsMethodArray, setPaymentsMethodArray] = useState(
      optionsMethod
      /* [
        { id: 1, description: 'netflix', img: require('../assets/products/netflix.png'), height: 40, width: 10, item: {netflix: true}, idBackend: "56"},
        { id: 2, description: 'spotify', img: require('../assets/products/spotify.png'), height: 32, width: 10, item: {spotify: true}, idBackend: "41"},
        { id: 3, description: 'office', img: require('../assets/products/office365.png'), height: 50, width: 10, item: {office365: true}, idBackend: "38"},
        { id: 4, description: 'minecraft', img: require('../assets/products/minecraft.png'), height: 50, width: 9, item: {'minecraft': true}, idBackend: "40" },
        { id: 5, description: 'xbox_one', img: require('../assets/products/xbox-one.png'), height: 52, width: 10, item: {'xbox-one': true}, idBackend: "" },
        { id: 6, description: 'playstation', img: require('../assets/products/ps.png'), height: 52, width: 10, item: {ps: true}, idBackend: "39" },
        { id: 7, description: 'rixty', img: require('../assets/products/rixty.png'), height: 40, width: 10, item: {rixty: true}, idBackend: "42" },
        { id: 8, description: 'imvu', img: require('../assets/products/imvu.png'), height: 40, width: 10, item: {imvu: true}, idBackend: "37"},
        { id: 9, description: 'xbox', img: require('../assets/products/xbox-one.png'), height: 52, width: 10, item: {'xbox': true}, idBackend: "36" },
        { id: 10, description: 'paramount', img: require('../assets/products/paramount.png'), height: 52, width: 10, item: {'paramount': true}, idBackend: "46" },
        { id: 11, description: 'deezer', img: require('../assets/products/deezer.png'), height: 52, width: 10, item: {'deezer': true}, idBackend: "43" },
        { id: 12, description: 'datacredito', img: require('../assets/products/midatacredito.png'), height: 52, width: 10, item: {'datacredito': true}, idBackend: "44" },
        { id: 13, description: 'winsport', img: require('../assets/products/winsport.png'), height: 52, width: 10, item: {'winsport': true} , idBackend: "47"},
        { id: 14, description: 'freefire', img: require('../assets/products/freefire.png'), height: 52, width: 10, item: {'freefire': true} , idBackend: "45"},
    ] */
    )
    const [paymentsMethod, setPaymentsMethod] = useState(null)
    const [currentPaymentMethod, setCurrentPaymentMethod] = useState(optionsMethod.find(item=>item.idBackend===currentMethod))
    const [listItemsProduct, setListItemsProduct] = useState([
    
    ]);

    const [currentProductItem, setcurrentProductItem] = useState(null)

    const [errorMessage, setErrorMessage] = useState('')
    const [loadingBuy, setLoadingBuy] = useState(false)
    const getPinPackage=async()=>{
            let name_ = currentPaymentMethod?.idBackend

           const resp=await axios.post('/pin_by_provider',{refresh,provider:name_}).catch((e) => {
            
             console.log("🚀 ~ getPinPackage ~ e:", e)
          })
          if (resp?.data?.status===200) {
            setListItemsProduct(resp.data?.data?.data)
          }

    }
      const buyPin=async () => {
        setLoadingBuy(true)
        let data = {
          id_pin: currentProductItem?.id_pin,
          operator: currentProductItem?.operator
        }

        const resp=await axios.post('/buy_pin',{refresh,data}).catch((e) => {})
        if (resp.data?.status===200) {
        navigate('/ComfirmationPage', { state:{pin:resp.data?.data?.data?.pin,title:'Completado!',headerText:'REF-'+resp.data?.data?.data?.pin_sale_id,dataInfo:[{labelL:'Fecha y hora',labelR:moment().format('MM-DD-YYYY, h:mm a')},{labelL:'Monto a comprar',labelR:formatCOPNumber(currentProductItem?.valor)},{labelL:'Monto debitado',labelR:formatCOPNumber(currentProductItem?.valor)}]} })
          
          console.log('ok')
        }else{
          if (resp.data?.data?.message) {
            setErrorMessage(resp.data?.data?.message)
          }else{
            setErrorMessage('Error inesperado')
          }
        }
        setLoadingBuy(false)
        console.log("🚀 ~ buyPin ~ resp:", resp)
        
      }

      
      

     
      useEffect(() => {
        if (currentPaymentMethod) {
          setcurrentProductItem(null)
          setListItemsProduct([])
          getPinPackage()
        }
      }, [currentPaymentMethod])
      
  return (
    <div className="bg-main h-screen max-h-screen flex-col items-center w-full flex">
      
            
            <BackgroundHome/>
            <div className='container-data'>

            <h1 className='text-center'>Comprar</h1>
            <h3 className='text-center my-2'>¿Qué quieres comprar?</h3>
            <div className='my-4 w-full'>

            <Carousel  
            paymentsMethodArray={paymentsMethodArray} 
            paymentsMethod={true}
            currentPaymentMethod={currentPaymentMethod} 
            onClickItem={(item) => {
              setCurrentPaymentMethod(item)
            }}/>
            </div>
            <h3 className='text-center mb-4'>Escoge el PIN a comprar</h3>
            
            <div className='flex flex-grow flex-col w-full overflow-scroll'>
            {
                listItemsProduct.map(
                    item=><ItemListProducts currentItem={currentProductItem} item={item} key={item.id} name={item.description} time={item.time} value={item.valor} onClick={()=>{
                        setcurrentProductItem(item)
                    }}/>
                    )
            }
            </div>

            {errorMessage!==''
            ?
            <p className='text-red-600 text-sm text-center'>{errorMessage}</p>
            :
            null}
            
            <button disabled={currentPaymentMethod===null||currentProductItem===null||loadingBuy} onClick={()=>buyPin()} className={`btn${currentPaymentMethod===null||currentProductItem===null||loadingBuy?'-disabled':''}`}>{loadingBuy?'Cargando...':`Pagar ${currentProductItem?.amount?formatCOPNumber(currentProductItem?.amount):''}`}</button>
            


            </div>
   
    </div>
  )
}

export default ProductServiceCobru