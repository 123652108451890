import './App.css';
import { BuyPhonePackage } from './pages/BuyPhonePackage';
import ProductServiceCobru from './pages/ProductServiceCobru';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { RechargePhone } from './pages/RechargePhone';
import { useEffect, useState } from 'react';
import ComfirmationPage from './pages/ComfirmationPage';
import { RechargeBetting } from './pages/RechargeBetting';
import { getIconsBetting } from './functions';
import { NotFound } from './pages/NotFound';
const rechargeOperators= [
  { id: 7, name: 'claro', img: require('./assets/mobile_recharge/claroNew.png') },
  { id: 8, name: 'tigo', img: require('./assets/mobile_recharge/tigoNew.png') },
  { id: 9, name: 'movistar', img: require('./assets/mobile_recharge/movistarNew.png') },
  { id: 10, name: 'virginmobile', img: require('./assets/mobile_recharge/virginNew.png') },
  { id: 12, name: 'movilexito', img: require('./assets/mobile_recharge/movilexitoNew.png') },
  { id: 13, name: 'wom', img: require('./assets/mobile_recharge/womNew.png') },
  { id: 14, name: 'etb', img: require('./assets/mobile_recharge/etbNew.png') },
  { id: 15, name: 'flashmobile', img: require('./assets/mobile_recharge/flash-mobileNew.png') },
  { id: 16, name: 'buenofon', img: require('./assets/mobile_recharge/buenofonNew.png') },
]

const digitalProductItems=[
  { id: 1, description: 'netflix', img: require('./assets/products/netflix.png'), height: 40, width: 10, item: {netflix: true}, idBackend: "56"},
  { id: 2, description: 'spotify', img: require('./assets/products/spotify.png'), height: 32, width: 10, item: {spotify: true}, idBackend: "41"},
  { id: 3, description: 'office', img: require('./assets/products/office365.png'), height: 50, width: 10, item: {office365: true}, idBackend: "38"},
  { id: 4, description: 'minecraft', img: require('./assets/products/minecraft.png'), height: 50, width: 9, item: {'minecraft': true}, idBackend: "40" },
  { id: 5, description: 'xbox_one', img: require('./assets/products/xbox-one.png'), height: 52, width: 10, item: {'xbox-one': true}, idBackend: "" },
  { id: 6, description: 'playstation', img: require('./assets/products/ps.png'), height: 52, width: 10, item: {ps: true}, idBackend: "39" },
  { id: 7, description: 'rixty', img: require('./assets/products/rixty.png'), height: 40, width: 10, item: {rixty: true}, idBackend: "42" },
  { id: 8, description: 'imvu', img: require('./assets/products/imvu.png'), height: 40, width: 10, item: {imvu: true}, idBackend: "37"},
  { id: 9, description: 'xbox', img: require('./assets/products/xbox-one.png'), height: 52, width: 10, item: {'xbox': true}, idBackend: "36" },
  { id: 10, description: 'paramount', img: require('./assets/products/paramount.png'), height: 52, width: 10, item: {'paramount': true}, idBackend: "46" },
  { id: 11, description: 'deezer', img: require('./assets/products/deezer.png'), height: 52, width: 10, item: {'deezer': true}, idBackend: "43" },
  { id: 12, description: 'datacredito', img: require('./assets/products/midatacredito.png'), height: 52, width: 10, item: {'datacredito': true}, idBackend: "44" },
  { id: 13, description: 'winsport', img: require('./assets/products/winsport.png'), height: 52, width: 10, item: {'winsport': true} , idBackend: "47"},
  { id: 14, description: 'freefire', img: require('./assets/products/freefire.png'), height: 52, width: 10, item: {'freefire': true} , idBackend: "45"},
]

function App() {
  const [refresh, setRefresh] = useState(null)
  const [optionsMethods, setoptionsMethods] = useState([])
  const [currentOptionsMethod, setcurrentOptionsMethod] = useState(null)

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const dataParam = urlParams.get('data');
    if (dataParam) {
      const data = JSON.parse(decodeURIComponent(dataParam));
      console.log("🚀 ~ useEffect ~ data:", data)
      
      if (data?.IsDigitalPines) {
        setoptionsMethods(digitalProductItems.filter(item=>{
          return data.optionsMethod.some(item2=>item2.method===item.idBackend)
        }))
      }
      if (data?.isRecharge) {
        setoptionsMethods(rechargeOperators.filter(item=>{
          return data.optionsMethod.some(item2=>item2.method===item.name)
        }))
      }
      if (data?.sportsBetting) {
        let newArray=data.optionsMethod?.map(item=>{
          
          return { ...item,img:getIconsBetting(item)}
        })
        setoptionsMethods(newArray)
      }
      setcurrentOptionsMethod(data.method)
      setRefresh(data.refresh)
/*       alert("🚀 ~ wiiiii ~:"+JSON.stringify(rechargeOperators.filter(item=>item.name===data.optionsMethod.method)))
 */      
    }
  }, []);
  return (
    <>
    <BrowserRouter>
    
    <Routes>
      {
        refresh?
        <>
        <Route path='/ProductServiceCobru' element={ <ProductServiceCobru optionsMethod={optionsMethods} currentMethod={currentOptionsMethod}  refresh={refresh}/>}/>
        <Route path='/BuyPhonePackage' element={<BuyPhonePackage optionsMethod={optionsMethods} currentMethod={currentOptionsMethod} refresh={refresh}/> }/>
        <Route path='/RechargePhone' element={<RechargePhone optionsMethod={optionsMethods} currentMethod={currentOptionsMethod} refresh={refresh}/>}/>
        <Route path='/ComfirmationPage' element={<ComfirmationPage  refresh={refresh}/>}/>
        <Route path='/RechargeBetting' element={<RechargeBetting optionsMethod={optionsMethods} currentMethod={currentOptionsMethod} refresh={refresh}/>}/>
        </>
        :
        null
      }
        <Route path='*' element={<NotFound/>}/>


    </Routes>
    </BrowserRouter>
    {/* <ProductServiceCobru/> */}
    {/* <BuyPhonePackage/> */}
    {/* <RechargePhone/> */}
  
    </>
  );
}

export default App;
