import React from 'react'


export const Carousel = ({ paymentsMethodArray, onClickItem = () => { }, paymentsMethod, currentPaymentMethod }) => {
    const ImageBank = (id) => {
        let img = null;
        switch (id) {
            default:
                img = null;
                break;
        }
        return img;
    };
    const getPadding = (method) => {
        switch (method) {
            case 'bancolombia_transfer':
            case 'corresponsal_bancolombia':
                return { padding: 0 }
                break;
            case 'DASH':
            case 'bancolombiapay':
                return { padding: 15 }
                break;
            default:
                return null
                break;
        }

    }
    return (
        <div className="paymentOptions">
            {paymentsMethod && paymentsMethodArray.map((item, i) => {

                return (
                    <div key={i}>
                        <button key={`item-${i}`} onClick={() => onClickItem(item)} className={`bg-white flex items-center justify-center paymentOptionsx ${item === currentPaymentMethod ? 'paymentOptionBorder' : ''}`} style={getPadding(Object.keys(item)[0])} >
                            <img className={`${item?.name?.credit_card ? 'imagen-data_' : ''} imagen-data`} alt={Object.keys(item)[0]} src={item?.img} />
                        </button>
                        {
                        }
                    </div>
                )
            })
            }

        </div>
    )
}
