import React, { useEffect, useState } from 'react'
import NavBarCobru from '../components/NavBarCobru'
import { Carousel } from '../components/Carousel'
import { ItemListProducts } from '../components/ItemListProducts'
import BackgroundHome from '../components/BackgroundHome'
import { FORMAT, formatCOPNumber } from '../functions'
import axios from 'axios'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import SelectModal from '../components/Inputs/SelectModal'

export const RechargeBetting = ({refresh,optionsMethod=[],currentMethod=null}) => {
  console.log("🚀 ~ RechargeBetting ~ optionsMethod:", optionsMethod)
  const navigate = useNavigate();

  const [paymentsMethodArray, setPaymentsMethodArray] = useState(
    optionsMethod
/*      [
    { id: 7, name: 'claro', img: require('../assets/mobile_recharge/claroNew.png') },
    { id: 8, name: 'tigo', img: require('../assets/mobile_recharge/tigoNew.png') },
    { id: 9, name: 'movistar', img: require('../assets/mobile_recharge/movistarNew.png') },
    { id: 10, name: 'virginmobile', img: require('../assets/mobile_recharge/virginNew.png') },
    { id: 12, name: 'movilexito', img: require('../assets/mobile_recharge/movilexitoNew.png') },
    { id: 13, name: 'wom', img: require('../assets/mobile_recharge/womNew.png') },
    { id: 14, name: 'etb', img: require('../assets/mobile_recharge/etbNew.png') },
    { id: 15, name: 'flashmobile', img: require('../assets/mobile_recharge/flash-mobileNew.png') },
    { id: 16, name: 'buenofon', img: require('../assets/mobile_recharge/buenofonNew.png') },
] */
)
  const [currentPaymentMethod, setCurrentPaymentMethod] = useState((optionsMethod.find(item=>item.id===currentMethod.id)))
    
    const [phone, setPhone] = useState('')
    const [currentProductItem, setcurrentProductItem] = useState(null)
      const [amount, setAmount] = useState('')
      const [errorMessage, setErrorMessage] = useState('')

    
    const [documentOptions, setDocumentOptions] = useState([])
    const [document_type, setDocument_type] = useState(null)
    const [document, setDocument] = useState('')
  const [loadingTransation, setloadingTransation] = useState(false)
    const sendDeposit=async() => {
      setloadingTransation(true)
      let data = {
      "provider_id":currentPaymentMethod?.provider_reference,
      "type_document":document_type?.value,
      "document_number":document,
      "amount": parseInt(amount.replace(/\D/g, ''))
  }

      const resp=await axios.post('/sport_bet_transaction',{refresh,data}).catch((e) => {})
      console.log("🚀 ~ sendDeposit ~ resp:", resp.data)
      
      if (resp.data?.status===200) {
        let dataService=resp.data?.data
         navigate('/ComfirmationPage', { state:{
          title:'Listo!',
          headerText:'REF-'+dataService?.authorizationCode,
          subtitle:dataService?.message,
          dataInfo:[
            {labelL:'Fecha y hora',labelR:moment().format('MM-DD-YYYY, h:mm a')},
            {labelL:'Documento',labelR:document_type?.label2+" "+document},
            {labelL:'Punto de Venta',labelR:dataService.ticket.body['Punto de Venta']},
            {labelL:'Talon',labelR:dataService.ticket.body['Talon']},
            {labelL:'Valor',labelR:dataService.ticket.body['Valor']},
          ]
            } 
           })
 
        console.log('ok')
      }else{
        if (resp.data?.data?.message) {
          setErrorMessage(resp.data?.data?.message)
        }else{
          setErrorMessage('Error inesperado')
        }
      }
      setloadingTransation(false)
      console.log("🚀 ~ buyPackage ~ resp:", resp)
    }


    const getTypesDocuments =async () => {
      const resp=await axios.post('/sports/get_types_documents',{refresh}).catch((e) => {})
      if (resp.data?.status===200) {
        let newArray=resp.data.data?.documentTypes?.map(item=>{
          return {
            label: item.identificationDescription,
            label2: item.identificationName,
            value: item.identificationType
        }
      })
      setDocumentOptions(newArray)
       let selectedDocument = newArray?.find(item=>item?.label2==='CC')
        setDocument_type(selectedDocument) 

      }
      console.log("🚀 ~ getTypesDocuments ~ resp:", resp)
      
    }
    useEffect(() => {
      getTypesDocuments()

      
    }, [])
    
useEffect(() => {
  console.log('currentPaymentMethods',currentPaymentMethod)
}, [currentPaymentMethod])

  return (
    <div className="bg-main h-screen max-h-screen flex-col items-center w-full flex">
            <BackgroundHome/>
            <div className='container-data'>

            <h1 className='text-center'>Deposito de cuenta</h1>
            <h3 className='text-center my-2'>Casas de apuesta</h3>
            <div className='my-4 w-full'>
            <Carousel  
            paymentsMethodArray={paymentsMethodArray} 
            paymentsMethod={true}
            currentPaymentMethod={currentPaymentMethod} 
            onClickItem={(item) => {
                setCurrentPaymentMethod(item)
            }}/>
            <div>
            <h3 className='text-center mb-2'>¿Cuánto vas a depositar?</h3>

            <input value={amount} className={`payment-input`} onChange={e=>setAmount(FORMAT.formatCOPNumberInput(e.target.value))} placeholder='$0'/>

            <div className="flex flex-row w-full my-1">
                        <div className={"w-3/5 min-w mr-3"}>
                          <SelectModal items={documentOptions} selectedItem={document_type} setSelectItem={setDocument_type} customLabelKey='label2' />
                          {
                            document_type?
                            <>

                            </>
                            :
                            null
                          }
                        </div>
                        <input placeholder={document_type?.label} className={`payment-input`} style={{'margin':0}}  value={document/* FORMAT.FormatDocument(document) */} type='number' onChange={(e) => setDocument(e.target.value/* FORMAT.GetDigits(e.target.value) */)} />
                    </div>
            </div>
            </div>
{/*             <div className='payment-input bg-white'>
              <div className='flex flex-row justify-between my-2 text-sm'>
                <p>Monto a Pagar</p>
                <p>{formatCOPNumber(10000)}</p>
              </div>
              <div className='flex flex-row justify-between text-gray-500 my-2 text-sm'>
                <p>Comisión Cobru</p>
                <p>{formatCOPNumber(0)}</p>
              </div>
              <div className='flex flex-row justify-between text-gray-500 my-2 text-sm'>
                <p>IVA Comisión Cobru</p>
                <p>{formatCOPNumber(0)}</p>
              </div>
              <div className='flex flex-row justify-between my-2 text-sm'>
                <p>Monto a Recargar</p>
                <p>{formatCOPNumber(10000)}</p>
              </div>
            </div> */}
            {/* <h3 className='text-center mb-4'>Escoge el PIN a comprar</h3> */}
     
            {errorMessage!==''
            ?
            <p className='text-red-600 text-sm text-center'>{errorMessage}</p>
            :
            null}

            <button disabled={loadingTransation||!amount||document==''} onClick={()=>sendDeposit()} className={`btn${loadingTransation||!amount||document==''?'-disabled':''}`}>{loadingTransation?"Cargando...":`Depositar ${ amount!==''?amount:''}`}</button>
            </div>
   
    </div>
  )
}
