import React from 'react'

const BackgroundHome = () => {
  return (
    <div className='w-full h-full absolute -top-14'>
        <img src={require('../assets/backgroundInicio.png')} className='-z-10 w-96 h-auto'/>
    </div>
  )
}

export default BackgroundHome