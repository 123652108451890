import React, { useEffect, useState } from 'react'
import NavBarCobru from '../components/NavBarCobru'
import { Carousel } from '../components/Carousel'
import { ItemListProducts } from '../components/ItemListProducts'
import BackgroundHome from '../components/BackgroundHome'
import { FORMAT, formatCOPNumber } from '../functions'
import axios from 'axios'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'

export const RechargePhone = ({refresh,optionsMethod=[],currentMethod=null}) => {
  const navigate = useNavigate();

  const [paymentsMethodArray, setPaymentsMethodArray] = useState(
    optionsMethod
/*      [
    { id: 7, name: 'claro', img: require('../assets/mobile_recharge/claroNew.png') },
    { id: 8, name: 'tigo', img: require('../assets/mobile_recharge/tigoNew.png') },
    { id: 9, name: 'movistar', img: require('../assets/mobile_recharge/movistarNew.png') },
    { id: 10, name: 'virginmobile', img: require('../assets/mobile_recharge/virginNew.png') },
    { id: 12, name: 'movilexito', img: require('../assets/mobile_recharge/movilexitoNew.png') },
    { id: 13, name: 'wom', img: require('../assets/mobile_recharge/womNew.png') },
    { id: 14, name: 'etb', img: require('../assets/mobile_recharge/etbNew.png') },
    { id: 15, name: 'flashmobile', img: require('../assets/mobile_recharge/flash-mobileNew.png') },
    { id: 16, name: 'buenofon', img: require('../assets/mobile_recharge/buenofonNew.png') },
] */
)
  const [currentPaymentMethod, setCurrentPaymentMethod] = useState(optionsMethod.find(item=>item.name===currentMethod))
    
    const [phone, setPhone] = useState('')
    const [currentProductItem, setcurrentProductItem] = useState(null)
      const [amount, setAmount] = useState('')
      const [errorMessage, setErrorMessage] = useState('')
      const [loadingReq, setloadingReq] = useState(false)
    const reloadPhone=async() => {
      setloadingReq(true)
      let data = {
        value:parseInt(amount.replace(/\D/g, '')),
        operator: currentPaymentMethod?.name,
        recipient: phone.replace(/\D/g, '')
    }

      const resp=await axios.post('/reload_phone',{refresh,data}).catch((e) => {})
      if (resp.data?.status===200&&resp.data?.data?.pk&&resp.data?.data?.result!=='error') {
        navigate('/ComfirmationPage', { state:{title:'Aprobada',headerText:'REF-'+resp.data?.data?.pk,subtitle:`Tu recarga ha ${phone} por ${amount} ha sido exitosa!`,dataInfo:[{labelL:'Fecha y hora',labelR:moment().format('MM-DD-YYYY, h:mm a')},{labelL:'Operador',labelR:currentPaymentMethod?.name,capitalize:true}]} })

        console.log('ok')
      }else{
        if (resp.data?.data?.message) {
          setErrorMessage(resp.data?.data?.message)
        }else{
          setErrorMessage('Error inesperado')
        }
      }
      setloadingReq(false)
      console.log("🚀 ~ buyPackage ~ resp:", resp)
    }

   /*  useEffect(() => {
      setcurrentProductItem(paymentsMethodArray[0])
    
    }, [])

    useEffect(() => {
      
    if (paymentsMethodArray[0]) {
      setCurrentPaymentMethod(paymentsMethodArray[0])
    }
    }, [paymentsMethodArray])
 */
  return (
    <div className="bg-main h-screen max-h-screen flex-col items-center w-full flex">
            <BackgroundHome/>
            <div className='container-data'>

            <h1 className='text-center'>Recarga de celular</h1>
            <h3 className='text-center my-2'>¿Cuál es tu operador?</h3>
            <div className='my-4 w-full'>
            <Carousel  
            paymentsMethodArray={paymentsMethodArray} 
            paymentsMethod={true}
            currentPaymentMethod={currentPaymentMethod} 
            onClickItem={(item) => {
                setCurrentPaymentMethod(item)
            }}/>
            <div>
            <h3 className='text-center mb-2'>¿Cuánto vas a recargar?</h3>

            <input value={amount} className={`payment-input`} onChange={e=>setAmount(FORMAT.formatCOPNumberInput(e.target.value))} placeholder='$0'/>

            <input value={phone} className={`payment-input`} onChange={e=>setPhone(FORMAT.formatPhoneNumber(e.target.value))} placeholder='Número de celular'/>

            </div>
            </div>
{/*             <div className='payment-input bg-white'>
              <div className='flex flex-row justify-between my-2 text-sm'>
                <p>Monto a Pagar</p>
                <p>{formatCOPNumber(10000)}</p>
              </div>
              <div className='flex flex-row justify-between text-gray-500 my-2 text-sm'>
                <p>Comisión Cobru</p>
                <p>{formatCOPNumber(0)}</p>
              </div>
              <div className='flex flex-row justify-between text-gray-500 my-2 text-sm'>
                <p>IVA Comisión Cobru</p>
                <p>{formatCOPNumber(0)}</p>
              </div>
              <div className='flex flex-row justify-between my-2 text-sm'>
                <p>Monto a Recargar</p>
                <p>{formatCOPNumber(10000)}</p>
              </div>
            </div> */}
            {/* <h3 className='text-center mb-4'>Escoge el PIN a comprar</h3> */}
     
            {errorMessage!==''
            ?
            <p className='text-red-600 text-sm text-center'>{errorMessage}</p>
            :
            null}

            <button disabled={amount===''||phone===""||loadingReq} onClick={()=>reloadPhone()} className={`btn${amount===''||phone===""?'-disabled':''}`}>{loadingReq?"Cargando...":`Recargar ${ amount!==''?amount:''}`}</button>
            </div>
   
    </div>
  )
}
