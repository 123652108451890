import React from 'react'
import '../css/moda.css'
import ListItem from './Inputs/ListItem'


function Modal({ showModal = false, setShowModal = () => { }, childrenClass = '', children = null }) {
  if (!showModal || !children) {
    return null
  }
  return (
    <div
      className='modal'

    >
      <div onClick={() => setShowModal(!showModal)} className='absolute w-full h-full'></div>
      <div className="modal-content max-w-sm z-10">
        <ListItem onClick={() => setShowModal(!showModal)} title={<span className="close ">&times;</span>} className='justify-end' />

        {children}
      </div>
    </div>
  )
}


export default Modal