import React from 'react'

export const NotFound = () => {
  return (
    <section class="flex items-center h-screen p-16 bg-gray-50">
    <div class="container flex flex-col items-center ">
        <div class="flex flex-col gap-6 max-w-md text-center">
            <h2 class="font-extrabold text-9xl text-gray-700">
                <span class="sr-only">Error</span>Ups!
            </h2>
            <p class="text-2xl md:text-3xl dark:text-gray-300">Pagina no encontrada</p>
        </div>
    </div>
</section>
  )
}
