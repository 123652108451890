import React, { useEffect, useState } from 'react'
import { Carousel } from '../components/Carousel'
import { ItemListProducts } from '../components/ItemListProducts'
import BackgroundHome from '../components/BackgroundHome'
import { FORMAT, formatCOPNumber } from '../functions'
import axios from 'axios'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'

export const BuyPhonePackage = ({refresh='',optionsMethod=[],currentMethod=null}) => {
  const navigate = useNavigate();

    const [paymentsMethodArray, setPaymentsMethodArray] = useState(
      optionsMethod
 /*      [
      { id: 7, name: 'claro', img: require('../assets/mobile_recharge/claroNew.png') },
      { id: 8, name: 'tigo', img: require('../assets/mobile_recharge/tigoNew.png') },
      { id: 9, name: 'movistar', img: require('../assets/mobile_recharge/movistarNew.png') },
      { id: 10, name: 'virginmobile', img: require('../assets/mobile_recharge/virginNew.png') },
      { id: 12, name: 'movilexito', img: require('../assets/mobile_recharge/movilexitoNew.png') },
      { id: 13, name: 'wom', img: require('../assets/mobile_recharge/womNew.png') },
      { id: 14, name: 'etb', img: require('../assets/mobile_recharge/etbNew.png') },
      { id: 15, name: 'flashmobile', img: require('../assets/mobile_recharge/flash-mobileNew.png') },
      { id: 16, name: 'buenofon', img: require('../assets/mobile_recharge/buenofonNew.png') },
  ] */
  )
    const [currentPaymentMethod, setCurrentPaymentMethod] = useState(optionsMethod.find(item=>item.name===currentMethod))
    const [listItemsProduct, setListItemsProduct] = useState(
      []
    );
    const [phone, setPhone] = useState('')
    const [currentProductItem, setcurrentProductItem] = useState(null)
      const [errorMessage, setErrorMessage] = useState('')
      const [loadingBuy, setLoadingBuy] = useState(false)
    const getPhonePackage=async()=>{
            let name_ = currentPaymentMethod?.name === 'movilexito' ? 'MOVIL_EXITO':  currentPaymentMethod?.name === 'virginmobile' ?'VIRGIN_MOBILE' :currentPaymentMethod?.name

           const resp=await axios.post('/package_by_provider',{refresh,provider:name_?.toString().toUpperCase()}).catch((e) => {
            
             console.log("🚀 ~ getPhonePackage ~ e:", e)
          })
          if (resp?.data?.status===200) {
            setListItemsProduct(resp.data?.data?.data)
          }

    }
      const buyPackage=async () => {
        setLoadingBuy(true)

        let data = {
          cell_provider: currentProductItem?.cell_provider,
          code_provider: currentProductItem?.code_provider,
          phone: phone.replace(/\D/g, '')
      }

        const resp=await axios.post('/buy_package',{refresh,data}).catch((e) => {})
        if (resp.data?.status===200) {
           navigate('/ComfirmationPage', { state:{subtitle:"Paquete comprado a "+phone,headerText:'REF-'+resp.data?.data?.data?.transaction_id,dataInfo:[{labelL:'Fecha y hora',labelR:moment().format('MM-DD-YYYY, h:mm a')},{labelL:'Monto debitado',labelR:formatCOPNumber(currentProductItem?.valor)},{labelL:'Operador',labelR:currentPaymentMethod?.name}]} })
          
          console.log('ok')
        }else{
          if (resp.data?.data?.message) {
            setErrorMessage(resp.data?.data?.message)
          }else{
            setErrorMessage('Error inesperado')
          }
        }
        console.log("🚀 ~ buyPackage ~ resp:", resp)
        setLoadingBuy(false)
      }

      
      

     /*  useEffect(() => {
        
      if (paymentsMethodArray[0]) {
        setCurrentPaymentMethod(paymentsMethodArray[0])
      }
      }, [paymentsMethodArray]) */
      useEffect(() => {
        if (currentPaymentMethod) {
          setcurrentProductItem(null)
          setListItemsProduct([])
          getPhonePackage()
        }
      }, [currentPaymentMethod])
      


  return (
    <div className="bg-main h-screen max-h-screen flex-col items-center w-full flex ">
            <BackgroundHome/>
            <div className='container-data'>

            <h1 className='text-center'>Paquetes Moviles</h1>
            <h3 className='text-center my-2'>¿Cuál es tu operador?</h3>
            <div className='my-4 w-full'>
            <Carousel  
            paymentsMethodArray={paymentsMethodArray} 
            paymentsMethod={true}
            currentPaymentMethod={currentPaymentMethod} 
            onClickItem={(item) => {
                setCurrentPaymentMethod(item)
            }}/>
            <div>
            <input value={phone} className={`payment-input`} onChange={e=>setPhone(FORMAT.formatPhoneNumber(e.target.value))} placeholder='Número de celular'/>

            </div>
            </div>
            {/* <h3 className='text-center mb-4'>Escoge el PIN a comprar</h3> */}
            <div className='flex flex-grow flex-col w-full overflow-scroll'>
            {
                listItemsProduct.map(
                    item=><ItemListProducts showValue={false} currentItem={currentProductItem} item={item} key={item.id} name={item.package_description} time={item.time} value={item.amount} onClick={()=>{
                        setcurrentProductItem(item)
                    }}/>
                    )
            }
            </div>
            {errorMessage!==''
            ?
            <p className='text-red-600 text-sm text-center'>{errorMessage}</p>
            :
            null}

            <button onClick={()=>buyPackage()} disabled={currentPaymentMethod===null||currentProductItem===null||phone?.replace(/\D/g, "").length<4||loadingBuy} className={`btn${currentPaymentMethod===null||currentProductItem===null||phone?.replace(/\D/g, "").length<4||loadingBuy?'-disabled':''}`}>{loadingBuy?'Cargando...':`Pagar ${currentProductItem?.amount?formatCOPNumber(currentProductItem?.amount):''}`}</button>

            </div>
   
    </div>
  )
}
