import React from 'react'
import { formatCOPNumber } from '../functions'

export const ItemListProducts = ({name='Playstation Saldo 10USD',time=null,value=15000,onClick=()=>{},currentItem=null,item=null,showValue=true}) => {
  return (
    <button className={`flex w-full justify-center items-center snap-center bg-white px-5 py-4 hover:border-solid hover:border-primary hover:border-2 hover:box-border rounded-xl my-3 ${item === currentItem&&currentItem!==null ? 'paymentOptionBorder' : ''}`} onClick={onClick}>
        <div className='flex flex-col flex-grow items-start'>

        <p className='text-base text-left'>{name}</p>
        <p className='text-gray-400 text-xs'>{!!time?time:'Sin vigencia'}</p>
        </div>
        {
          showValue?

          <div className=''>

        <p >{formatCOPNumber(value)}</p>
        </div>
        :
        null
        }
    </button>
  )
}
