import React from 'react'

const ListItem = ({ title = '', subtitle = '', onClick = () => { }, iconRight = null, className = '', selectedItem = '' }) => {
  return (
    <div
      style={{ borderBottomWidth: '1px', borderColor: '#F1F1F1' }}
      className={`flex items-center cursor-pointer px-4 py-2 text-base ${className}`}
      onClick={() => onClick()}
    >
      {title}
    </div>
  )
}

export default ListItem